'use client'

import { PublicOrganizationDocument } from '../../../models/organization/OrganizationInterface'

export const CURRENT_ORG_ID_STORAGE_KEY = 'currentOrgId'

export const getCurrentOrgId = (): string | null => {
  const currentOrgId = global?.localStorage?.getItem(CURRENT_ORG_ID_STORAGE_KEY)
  if (
    currentOrgId === 'null' ||
    currentOrgId === null ||
    currentOrgId === undefined ||
    currentOrgId === '' ||
    currentOrgId === 'undefined'
  ) {
    clearStorageData()
    return null
  }
  return currentOrgId
}

export const setCurrentOrgId = (orgId: string): void => {
  global?.localStorage?.setItem(CURRENT_ORG_ID_STORAGE_KEY, orgId)
}

export const getCurrentOrg = (organizations: PublicOrganizationDocument[]): PublicOrganizationDocument | null => {
  const currentOrgId = getCurrentOrgId()
  const org = organizations?.find((org) => org.id === currentOrgId)

  if (org) {
    return org
  }

  if (organizations.length > 0) {
    setCurrentOrgId(organizations[0].id)
    return organizations[0]
  }

  return null
}

export const clearStorageData = (): void => {
  global?.localStorage?.removeItem(CURRENT_ORG_ID_STORAGE_KEY)
}
