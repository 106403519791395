import useSWR, { KeyedMutator } from 'swr'
// utils
import logger from 'src/utils/logger'
import { useMemo } from 'react'
import { paths } from 'src/routes/paths'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { getCurrentOrgId } from 'src/utils/local_storage'
import { App_Type_Key, endpoints, fetcher, poster, Slack_Redirection_Key } from '../utils/axios'
import { swrOpts } from '../providers/swr'
import {
  BaseUserIDocument,
  BaseUserDocumentWithId,
  UserLatestConnectionsRespose,
  CurrentUserDocument,
} from '../../../models/user/BaseUserInterface'
import { StandardResponse } from '../../../models/utils/UtilsInterface'
import { Constants } from '../../../utils/constants'

// ----------------------------------------------------------------------

interface UseGetCurrentUserResult {
  currentUser: CurrentUserDocument
  isLoading: boolean
  isError: boolean
  isValidating: boolean
  isEmpty: boolean
  mutateCurrentUser: KeyedMutator<CurrentUserDocument>
}

export function useCurrentUser(): UseGetCurrentUserResult {
  const URL = endpoints.user.getUser

  // we have to pass the fetcher and opts in here because useCurrentUser gets called
  // outside of the SWRProvider (in AuthProvider)
  const { data, error, isLoading, isValidating, mutate } = useSWR([URL, {}], fetcher, swrOpts)

  const memoizedValue: UseGetCurrentUserResult = useMemo(
    () => ({
      currentUser: data?.user,
      isLoading,
      isValidating,
      isError: error,
      isEmpty: !isLoading && !data?.user,
      mutateCurrentUser: mutate,
    }),
    [data, isLoading, isValidating, error, mutate]
  )

  return memoizedValue
}

interface UseGetOrganizationUsersResult {
  users: BaseUserIDocument[]
  isLoading: boolean
  isError: boolean
  isValidating: boolean
  isEmpty: boolean
}

export function useOrganizationUsers(): UseGetOrganizationUsersResult {
  const currentOrgId = getCurrentOrgId()
  const URL = endpoints.user.getOrganizationUsers

  const { data, error, isLoading, isValidating } = useSWR([URL, { organizationId: currentOrgId }], poster)

  // Use useMemo to memoize the computed result
  const memoizedValue: UseGetOrganizationUsersResult = useMemo(
    () => ({
      users: (data?.users ?? []) as BaseUserIDocument[],
      isLoading,
      isError: !!error,
      isValidating, // You may adjust this value as needed
      isEmpty: !isLoading && !data?.users?.length,
    }),
    [data?.users, isValidating, isLoading, error]
  )

  return memoizedValue
}

export async function fetchSingleUser(userId: string): Promise<BaseUserIDocument | undefined> {
  const URL = endpoints.user.getPublicUser

  try {
    const params = { userId }
    const { user } = (await fetcher([URL, params])) as { user: BaseUserIDocument }
    return user
  } catch (error) {
    logger.error('Error in fetchSingleUser', {}, error as Error)
    return undefined
  }
}

interface UpdateProfileResult {
  updatedUser?: BaseUserDocumentWithId
  isLoading: boolean
  isError: boolean
}

export async function updateProfile({
  firstName,
  lastName,
  bio,
  avatarUrl,
}: {
  firstName?: string
  lastName?: string
  bio?: string
  avatarUrl?: string
}): Promise<UpdateProfileResult> {
  try {
    const URL = endpoints.user.updateProfile
    const params = { firstName, lastName, bio, avatarUrl }
    const response = await poster([URL, params])
    return {
      updatedUser: response.updatedUser,
      isLoading: false,
      isError: false,
    }
  } catch (error) {
    logger.error('Error in updateProfile', {}, error as Error)
    return { updatedUser: undefined, isLoading: false, isError: true }
  }
}

export async function sendEmailOTP({ firstName, email }: { firstName: string; email: string }): Promise<boolean> {
  try {
    const URL = endpoints.user.updateEmailAddress
    const params = { firstName, email }
    const response = await poster([URL, params])
    return response
  } catch (error) {
    logger.error('Error in sendEmailOTP', {}, error as Error)
    return false
  }
}

export async function verifyEmailOTP({ email, emailOTP }: { email: string; emailOTP: string }): Promise<boolean> {
  try {
    const URL = endpoints.user.verifyEmailOtp
    const params = { email, emailOTP }
    const response = await poster([URL, params])
    return response.success
  } catch (error) {
    logger.error('Error in verifyEmailOTP', { emailOTP }, error as Error)
    return false
  }
}

interface UpdateEmailPreferencesResult {
  updatedUser?: BaseUserDocumentWithId
  isError: boolean
}

export async function updateEmailPreferences({
  marketing,
  productUpdates,
}: {
  marketing: boolean
  productUpdates: boolean
}): Promise<UpdateEmailPreferencesResult> {
  try {
    const URL = endpoints.user.updateEmailPreferences
    const params = { marketing, productUpdates }
    const response = await poster([URL, params])
    return {
      updatedUser: response.updatedUser,
      isError: false,
    }
  } catch (error) {
    logger.error('Error in updateEmailPreferences', { marketing, productUpdates }, error as Error)
    return {
      updatedUser: undefined,
      isError: true,
    }
  }
}

export async function logoutUser(router: AppRouterInstance, method: string): Promise<void> {
  try {
    if (method === Constants.JWT_LOGIN_METHOD) {
      router.replace(paths.auth.tab.logout)
      localStorage.removeItem('Authorization')
      return
    }

    if (method === Constants.SLACK_LOGIN_METHOD || localStorage.getItem(App_Type_Key) === 'slack_app') {
      await poster([endpoints.auth.logout, {}])
      localStorage.removeItem(Slack_Redirection_Key)
      localStorage.removeItem(App_Type_Key)
      router.replace(paths.auth.slack.login)
      return
    }

    await poster([endpoints.auth.logout, {}])
    router.replace(method === Constants.MICROSOFT_LOGIN_METHOD ? paths.auth.microsoft.login : paths.auth.teams.login)
  } catch (error) {
    logger.error('error logging out', {}, error as Error)
  }
}

export async function sendEmailOtpAPICall({
  email,
  firstName,
}: {
  email: string
  firstName: string
}): Promise<StandardResponse> {
  try {
    const URL = endpoints.user.sendEmailOtp
    const params = { email, firstName }

    const response = (await poster([URL, params])) as StandardResponse

    return response
  } catch (error) {
    logger.error('error in sendEmailOtpAPICall', { email, firstName }, error as Error)
    return {
      success: false,
      error: true,
      errorMessage: 'Failed to send email OTP',
    }
  }
}

export async function verifyEmailOtpAPICall({
  email,
  emailOTP,
}: {
  email: string
  emailOTP: string
}): Promise<StandardResponse> {
  try {
    const URL = endpoints.user.verifyEmailOtp
    const params = { email, emailOTP }

    const response = (await poster([URL, params])) as StandardResponse

    return response
  } catch (error) {
    logger.error('error in VerifyEmailOtpAPICall', { emailOTP }, error as Error)
    return {
      success: false,
      error: true,
      errorMessage: 'There was an issue checking the verification code. Please contact support.',
    }
  }
}

export async function checkUserEmailExistsAPICall({ email }: { email: string }): Promise<StandardResponse> {
  try {
    const URL = endpoints.user.checkEmailExists
    const params = { email }

    const response = (await poster([URL, params])) as StandardResponse

    return response
  } catch (error) {
    logger.error('error in checkUserEmailExistsAPICall', { email }, error as Error)
    return {
      success: false,
      error: true,
      errorMessage: 'Failed to check if email exists',
    }
  }
}

interface UseGetUserflowSignatureResult {
  signature: string | undefined
  isLoading: boolean
  isError: boolean
}

export function useUserflowSignature(): UseGetUserflowSignatureResult {
  const URL = endpoints.user.getUserFlowSignature

  // we have to pass the fetcher and opts in here because useUserflowSignature gets called
  // outside of the SWRProvider (in AuthProvider)
  const { data, error, isLoading } = useSWR<{ signature: string }>([URL, {}], fetcher, swrOpts)

  const response = {
    signature: data?.signature,
    isLoading,
    isError: !!error,
  }

  return response
}

export type UserOnboardingParams = {
  firstName: string
  lastName: string
  email: string
  role: { selectionValue: string; selectionText: string }
  termsAcceptedStatus: boolean
  marketingInformationNotification: boolean
  productUpdateEmailNotification: boolean
}

export async function userOnboardingAPICall({
  firstName,
  lastName,
  email,
  role,
  termsAcceptedStatus,
  marketingInformationNotification,
  productUpdateEmailNotification,
}: UserOnboardingParams): Promise<StandardResponse> {
  if (!firstName || firstName.length === 0) {
    return {
      success: true,
      error: false,
      errorMessage: '',
    }
  }

  const params = {
    firstName,
    lastName,
    email,
    workRole: role,
    termsAcceptStatus: termsAcceptedStatus,
    marketingInformationNotification,
    productUpdateEmailNotification,
  }

  try {
    const URL = endpoints.user.userOnboarding

    const response = (await poster([URL, params])) as StandardResponse

    if (response.error) {
      throw new Error('Network response was not ok')
    }

    return response
  } catch (error) {
    logger.error('error in userOnboardingAPICall', params, error as Error)
    return {
      success: false,
      error: true,
      errorMessage: 'Failed to submit onboarding information',
    }
  }
}

export async function updateNextNPSPopupDate({ userId }: { userId: string }): Promise<Date | null> {
  try {
    const URL = endpoints.user.updateNextNPSPopupDate
    const response = await poster([URL, { userId }])
    return response.nextDate
  } catch (error) {
    logger.error('Error in updateNextNPSPopupDate', { userId }, error as Error)
    return null
  }
}

interface GetLatestConnectionsResult {
  latestConnections: UserLatestConnectionsRespose
  isLoading: boolean
  isError: boolean
}

export function useLatestConnections(): GetLatestConnectionsResult {
  const currentOrgId = getCurrentOrgId()
  const URL = endpoints.user.getLatestConnections

  const params = currentOrgId ? { organizationId: currentOrgId } : {}

  const { data, error, isLoading } = useSWR<UserLatestConnectionsRespose>([URL, params])

  const response = {
    latestConnections: data ?? {
      latestConnections: [],
    },
    isLoading,
    isError: !!error,
  }

  return response
}
